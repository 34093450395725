import React from "react"
import styled from "styled-components"

import { Box } from "../Box"

const OrderedListWrapper = styled(Box)`
  list-style-type: decimal;

  p {
    margin: 0;
    font-size: ${props => props.theme.fontSize["sm"]};
    line-height: 1.45;
    color:var(--textDesc);;
  }
`

const OrderedList = ({ children, ...props }) => (
  <OrderedListWrapper
    element="ol"
    backgroundColor="black"
    backgroundTint="light"
    marginTop={4}
    marginBottom={4}
    padding={0}
    paddingLeft={10}
    textColor="silver"
    textTint="darker"
    {...props}
  >
    {children}
  </OrderedListWrapper>
)

export default OrderedList
