import React from "react"
import styled from "styled-components"

const StyledLink = styled.a`
  color: var(--textLink);
  text-decoration: none;
  background-image:;
  position: relative;
  text-decoration: none;
  display: inline-block;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width:  ${props => (props.underlined ? "100%" : "0")};
    height: 8%;
    left: 0;
    bottom: 0;
    background-color: Gold;
    transition: all ease 0.3s;
    opacity: 1;
  }
  
  &:hover,
  &:focus,
  &:active {
    color:gold;
    &::after {
      height: 100%;
      opacity: 0.8;
      background-color: transparent;
    }
  }
  @media (max-width: 414px) {
    display:inline;
    color:gold;
    &::after {
      display:none;
    }
  }
`

const Link = ({
  children,
  element,
  color,
  tint = "default",
  isCurrent,
  underlined,
  ...props
}) => {
  return (
    <StyledLink
      as={element || "a"}
      color={color}
      tint={tint}
      underlined={underlined}
      {...props}
    >
      {children}
    </StyledLink>
  )
}

export default Link
