import React from "react"
import styled from "styled-components"

const Element = styled.h1`
  font-size: 2.5rem;
  /* 36px */
  line-height: 2.75rem;
  /* 40px */
  margin-bottom: 1.5rem;
  /* 24px */
  letter-spacing: -1.5px;
  color:var(--textTitle);
`

const Heading1 = ({ children, element, color, tint = "default", ...props }) => (
  <Element as={element || "h1"} color={color} tint={tint} {...props}>
    {children}
  </Element>
)

export default Heading1
