import React from "react"
import styled from "styled-components"

const Element = styled.span`
    position: relative;
    &:after {
      color:gold;
      position: absolute;
      top: 0.65em;
      left: 0;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      content: "・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・";
    }
    @media (max-width: 414px) {
      em{
        font-weight:bold;
        &:after {
          display:none;
        }
      }
    }
`

const TextEm = ({ children, element, color, tint = "default", ...props }) => {
  return (
    <Element as={element || "em"} color={color} tint={tint} {...props}>
      {children}
    </Element>
  )
}

export default TextEm
