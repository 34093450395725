import React from "react"
import styled from "styled-components"

const Element = styled.h4`
  font-size: ${props => props.theme.fontSize.xl};
  color:var(--textTitle);
  font-size: 1.12rem;
  /* 16px */
  line-height: 1.375rem;
  /* 22px */
  margin-bottom: 1.5rem;
`

const Heading4 = ({ children, element, color, tint = "default", ...props }) => (
  <Element as={element || "h4"} color={color} tint={tint} {...props}>
    {children}
  </Element>
)

export default Heading4
