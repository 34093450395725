import React from "react"
import styled from "styled-components"

const Element = styled.h2`
  font-size: 1.63rem;
  /* 24px */
  line-height: 2rem;
  /* 30px */
  margin-bottom: 0.5rem;
  /* 24px */
  letter-spacing: -1px;
  font-weight: 400;
  color:var(--textTitle);
`

const Heading2 = ({ children, element, color, tint = "default", ...props }) => (
  <Element as={element || "h2"} color={color} tint={tint} {...props}>
    {children}
  </Element>
)

export default Heading2
