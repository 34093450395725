// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-mdx-js": () => import("./../src/templates/Blog/mdx.js" /* webpackChunkName: "component---src-templates-blog-mdx-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-speech-js": () => import("./../src/pages/speech.js" /* webpackChunkName: "component---src-pages-speech-js" */),
  "component---src-pages-talks-managing-time-js": () => import("./../src/pages/talks/managing-time.js" /* webpackChunkName: "component---src-pages-talks-managing-time-js" */),
  "component---src-pages-talks-meetings-js": () => import("./../src/pages/talks/meetings.js" /* webpackChunkName: "component---src-pages-talks-meetings-js" */),
  "component---src-pages-writings-js": () => import("./../src/pages/writings.js" /* webpackChunkName: "component---src-pages-writings-js" */)
}

