import React from "react"

import { Box } from "../Box"
import styled from "styled-components"

const QuoteContainer = styled(Box)`
  p {
    margin: 0;
    color: var(--textDesc);
    line-height: 1.35;
  }
  ul{
    color: var(--textDesc);
  }
  a{
    color:var(--textDesc);
    &:hover{
      color: var(--textLink);
    }
  }
`

const Quote = ({ children }) => (
  <QuoteContainer
    element="blockquote"
    backgroundColor="black"
    backgroundTint="light"
    marginTop={8}
    marginBottom={8}
    padding={4}
  >
    {children}
  </QuoteContainer>
)

export default Quote
